import {locator} from "@/src/core/app/ioc";
import {TYPES} from "@/src/core/app/ioc/types";
import {CmsApiService} from "@/src/core/app/data/services/cms_api_service";
import {CMS_NodeJobOffer, CMS_NodeJobOffer_Schedule} from "@/src/core/app/domain/cms/@types/node/CMS_NodeJobOffer";
import {AppInfo} from "@/src/core/app/domain/@types/AppInfo";
import {NetworkResponse} from "@/src/common/network/NetworkResponse";
import {EventRequestFormSuccessOutput} from "@/src/core/app/domain/event/@types/Output/EventRequestFormSuccessOutput";
import {EventRequestFormErrorOutput} from "@/src/core/app/domain/event/@types/Output/EventRequestFormErrorOutput";
import {FormsApiService} from "@/src/core/app/data/services/forms_api_service";
import DataLoaderContext from "@/src/core/app/data/data-loader/context/DataLoaderContext";
import CMS_AuthUtils from "@/src/core/app/domain/cms/utils/auth";

const JobOfferUtils = {
  getList: async (
    context: DataLoaderContext,
    pageSize: number,
    langcode: string
  ) : Promise<CMS_NodeJobOffer[]> => {
    const cms = locator.get<CmsApiService>(TYPES.CmsApiService);
    const data = await cms.getJobOffers(context, pageSize, langcode);
    return data.data as CMS_NodeJobOffer[]
  },

  getScheduleLabel: (schedule: CMS_NodeJobOffer_Schedule) => {
    if (schedule === CMS_NodeJobOffer_Schedule.FULL) {
      return 'job_offer_utils.schedule_label.full'
    }
    return 'job_offer_utils.schedule_label.full';
  },

  sendJobOfferForm: async (
    appInfo: AppInfo,
    jobOffer: string,
    cvFileName: string,
    cvFileData: string
  ) : Promise<NetworkResponse<EventRequestFormSuccessOutput, EventRequestFormErrorOutput>> => {
    const authHeaders = CMS_AuthUtils.getAuthHeaders();
    const api = locator.get<FormsApiService>(TYPES.FormsApiService);
    return await api.jobOfferForm(appInfo, jobOffer, cvFileName, cvFileData, authHeaders);
  },
};

export default JobOfferUtils;
