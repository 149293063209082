import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {T_HotelRoomBasic} from "@/src/core/app/domain/models/hotel-room/T_HotelRoomBasic";
import ModalLayoutFullscreen from "@/src/ui/components/portals/generic/ModalLayoutFullscreen";
import _Summary from "@/src/ui/components/portals/mobile/ModalHotelRoom/_Summary";
import React from "react";
import FullWidthGalleryMobile from "src/ui/components/mobile/FullwidthGalleryMobile";

const ModalHotelRoomMobile = () => {
  const modalState = useModalProvider(state => state);
  const room = modalState.modalData.room as T_HotelRoomBasic;

  return (
    <ModalLayoutFullscreen
      wrapperClassName='max-h-full'
      contentClassName='flex flex-col max-h-full'
      onCloseModal={() => modalState.closeModal()}
    >
      <div className='p-5 pt-15 border-b border-gray-20'>
        <h2 className="title-3-mobile text-center">{room.title}</h2>
      </div>
      <div className="flex-initial overflow-hidden flex flex-col">
        <div className="flex-initial overflow-hidden relative">
          <div className="max-h-full overflow-y-scroll pb-4">
            <_Summary {...room} />
          </div>
          <div
            className="absolute inset-x-0 bottom-0 h-12 bg-gradient-to-b from-transparent via-white to-white">&nbsp;</div>
        </div>
        <FullWidthGalleryMobile items={room.ui_cpt_field_room_media_popup}/>
      </div>
    </ModalLayoutFullscreen>
  )
};

export default ModalHotelRoomMobile;