import {RouteProps} from "@/src/ui/@types/RouteProps";
import {BookingConfirmationRouteProps} from "@/src/core/app/domain/route/@types/BookingConfirmationRouteProps";
import BookingConfirmationDesktop from "@/src/ui/components/routes/Route_BookingConfirmation/desktop";
import BookingConfirmationMobile from "@/src/ui/components/routes/Route_BookingConfirmation/mobile";
import AnalyticsTransactionCart from "@/src/ui/components/analytics/browser/AnalyticsTransactionCart";
import DataLayerHotelId from "@/src/ui/components/analytics/browser/DataLayerHotelId";
import DataLayerBookingConfirmation from "@/src/ui/components/analytics/browser/DataLayerBookingConfirmation";

const Route_BookingConfirmation = (props: RouteProps<BookingConfirmationRouteProps>) => {
  const _Component = props.device.isMobile ? BookingConfirmationMobile : BookingConfirmationDesktop;
  return (
    <>
      <_Component {...props} />
      <DataLayerHotelId hotelId={props.routeData.data.hotel.code} />
      <DataLayerBookingConfirmation reservation={props.routeData.data.reservation} />
      <AnalyticsTransactionCart
        checkout={props.routeData.data.hotel}
        reservation={props.routeData.data.reservation}
      />
    </>
  );
};

export default Route_BookingConfirmation;
