import {T_ParagraphJobOfferInfo} from "@/src/core/app/domain/models/paragraph/T_ParagraphJobOfferInfo";
import {useState} from "react";
import cn from "classnames";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import MediaImage from "@/src/ui/components/generic/MediaImage";
import _JobOffers from "@/src/ui/components/blocks/BlockJobOfferInfo/job-offers";
import TitleWithTag from "@/src/ui/components/generic/TitleWithTag";
import {useAppInfo} from "@/src/ui/hooks/useAppInfo";

const BlockJobOfferInfo = (props: T_ParagraphJobOfferInfo) => {
  const appInfo = useAppInfo();
  const tabs = props.field_job_offer_info_items.map((item, index) => ({
    key: `tab-${index}`,
    title: item.field_title,
    index,
  }));
  tabs.push({
    key: 'job-offers',
    title: props.field_joi_job_offer_tab_title,
    index: tabs.length,
  });

  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const currentItem = currentTab.key.startsWith('tab-') ?
    props.field_job_offer_info_items[currentTab.index] :
    null
  ;

  return (
    <>
      <TitleWithTag
        className="title-4 pb-7.5 font-calistoga"
        field={props.field_title_with_tag}
      />
      <div className="rounded-sm border border-gray-40">
        {appInfo.langcode === 'es' && (
          <ul className="py-5 flex divide-x divide-x-gray-20 overflow-x-auto">
            {tabs.map((item, index) => (
              <li
                key={item.key}
                className={cn(
                  index === 0 && "pl-3.75 sm:pl-5",
                  "px-4 uppercase text-xs cursor-pointer whitespace-nowrap",
                  tabs[index].key === currentTab.key ? "font-bold text-brand-1" : "text-brand-2"
                )}
                onClick={() => {
                  setCurrentTab(item)
                }}
              >
            <span className="block">
              {item.title}
            </span>
                {tabs[index].key === currentTab.key && (
                  <div className="h-0.5 bg-brand-1 transform translate-y-5"/>
                )}
              </li>
            ))}
          </ul>
        )}
        <div className="px-3.75 h-1px bg-gray-20"/>
        {currentItem && (
          <div className='p-3.75'>
            <WrapperDiv
              className="text-sm text-brand-2-90 Cms-Formatted"
              dangerouslySetInnerHTML={{__html: currentItem.field_body.processed}}
            />
            {currentItem.field_block_image && (
              <div className='mt-5'>
                <MediaImage mediaImage={currentItem.field_block_image}/>
              </div>
            )}
          </div>
        )}
        {!currentItem && (
          <_JobOffers {...props} />
        )}
      </div>
    </>
  );
}
export default BlockJobOfferInfo;
