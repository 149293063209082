import FormInputText from "@/src/ui/components/formik/FormInputText";
import Button from "@/src/ui/components/generic/Button";
import {FormikProps} from "formik/dist/types";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {Formik} from "formik";
import FormUtils from "@/src/ui/utils/form";
import Anchor from "@/src/ui/components/generic/Anchor";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import {NetworkResponseError} from "@/src/common/network/NetworkResponse";
import FormErrorUtils from "@/src/core/app/utils/form-error";
import TitledBox from "@/src/ui/components/generic/TitledBox";
import {UserTmsAssignFormValues} from "@/src/core/app/domain/forms/UserTmsAssignFormValues";
import {T_ParagraphUserTmsAssignForm} from "@/src/core/app/domain/models/paragraph/T_ParagraphUserTmsAssignForm";
import UserTmsAssignUtils from "@/src/core/app/utils/user-tms-assign";
import UserTmsAssignErrors from "@/src/core/app/domain/user/@types/FormErrorMap/user-tms-assign";
import {T_NotificationType} from "@/src/ui/@types/T_Notification";
import UserUtils from "@/src/core/app/utils/user";
import {useTranslation} from "next-i18next";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutCommon} from "@/src/ui/@types/layout/UI_LayoutCommon";
import {useAppInfo} from "@/src/ui/hooks/useAppInfo";
import UrlUtils from "@/src/core/app/utils/url";

interface _FormProps {
  paragraph: T_ParagraphUserTmsAssignForm
  formik: FormikProps<UserTmsAssignFormValues>
}

interface _Props {
  paragraph: T_ParagraphUserTmsAssignForm
}

const _Form = (props: _FormProps) => {
  const { t } = useTranslation();
  const appInfo = useAppInfo();
  return (
    <TitledBox titleWithTag={props.paragraph.field_title_with_tag}>
      <div className="px-5 py-3.75 pb-5 space-y-5 sm:space-y-7.5">
        <FormInputText
          name='clientId'
          label={t('UserTmsAssignForm._Index.client_id_label') + " *"}
          type='text'
          validate={FormUtils.validateIsRequired}
        />
        <div className='space-x-2'>
          <Button
            text={t('UserTmsAssignForm._Index.submit_button_label')}
            bold
            onClick={props.formik.handleSubmit}
            isActive={!props.formik.isSubmitting}
          />
          <Anchor
            className='text-sm text-brand-2 hover:underline'
            href={UrlUtils.getHomepageUrl(appInfo.langcode)}
          >
            {t('UserTmsAssignForm._Index.back_link_label')}
          </Anchor>
        </div>
      </div>
    </TitledBox>
  );
}

const UserTmsAssignForm = (props: _Props) => {
  const layoutData = useLayoutData<UI_LayoutCommon>();
  const {t} = useTranslation();
  const modalState = useModalProvider(state => state);
  let initialValues : UserTmsAssignFormValues = {
    clientId: '',
  };

  return (
    <Formik<UserTmsAssignFormValues>
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, formikHelpers) => {
        formikHelpers.setSubmitting(true);
        modalState.toggleModal(ModalKey.LOADER, {
          message: 'global.generic_loader.message'
        });

        const response = await UserTmsAssignUtils.assign(values.clientId);
        if (!response.isOk) {
          const networkError = response.error as NetworkResponseError;
          FormUtils.handleNetworkError({
            networkError,
            modalState,
            formikHelpers,
            t,
            formErrorMap: UserTmsAssignErrors,
            logFn: (reason) => FormErrorUtils.logNetworkError('TMS assign form error', reason, networkError),
          });
          return;
        }

        modalState.toggleModal(ModalKey.NOTIFICATION, {
          type: T_NotificationType.SUCCESS,
          title: 'UserTmsAssignForm._Index.success_notification_title',
          message: 'UserTmsAssignForm._Index.success_notification_message',
          onClose: () => {
            UserUtils.goToUserHome(layoutData.sitePageUrls);
          }
        });
      }}
    >
      {(formik: FormikProps<UserTmsAssignFormValues>) => (
        <_Form
          formik={formik}
          paragraph={props.paragraph}
        />
      )}
    </Formik>
  );
}

export default UserTmsAssignForm;
