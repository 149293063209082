import {T_NodeBlogDetail} from "@/src/core/app/domain/models/node/T_NodeBlogDetail";
import BlogUtils from "@/src/core/app/utils/blog";
import Anchor from "@/src/ui/components/generic/Anchor";
import WrapperDiv from "@/src/ui/components/generic/WrapperDiv";
import Carousel from "@/src/ui/components/generic/Carousel";
import UiTaxonomyTermUtils from "@/src/ui/utils/taxonomy-term";

const _Body = (props: T_NodeBlogDetail) => {
  const dateAndSection = (
    <p className="text-xs text-brand-2-60 uppercase">
      {BlogUtils.getDateCreated(props.created)}
      {props.taxonomy_term && (
        <>
          <span>{' - '}</span>
          <Anchor className='uppercase' href={UiTaxonomyTermUtils.getUrl(props.taxonomy_term)}>
            {props.taxonomy_term.title}
          </Anchor>
        </>
      )}
    </p>
  );

  return (
    <section className="pb-7.5">
      <div className='mb-5'>
        <h1 className="pb-1 mb-px title-4-mobile">{props.title}</h1>
        {dateAndSection}
      </div>
      <div className="space-y-7.5 pb-5">
        {props.ui_cpt_field_blog_post_media_mobile.length > 0 && (
          <Carousel
            className='w-full'
            medias={props.ui_cpt_field_blog_post_media_mobile}
          />
        )}
        <WrapperDiv
          className="blog-post-content Cms-Formatted"
          dangerouslySetInnerHTML={{__html: props.field_body.processed}}
        />
      </div>
      {dateAndSection}
    </section>
  )
}

export default _Body;