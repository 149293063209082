import Button from "@/src/ui/components/generic/Button";
import {FormikProps} from "formik/dist/types";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {Form, Formik} from "formik";
import FormRecaptcha from "@/src/ui/components/formik/FormRecaptcha";
import {CateringDataItem, EventFormValues} from "@/src/core/app/domain/forms/EventFormValues";
import FormTermsAndConditions from "@/src/ui/components/formik/FormTermsAndConditions";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import {T_NotificationType} from "@/src/ui/@types/T_Notification";
import {_FormProps, _Props} from "@/src/ui/components/forms/EventForm/types";
import _Person from "@/src/ui/components/forms/EventForm/person";
import _Event from "@/src/ui/components/forms/EventForm/event";
import _Rooms from "@/src/ui/components/forms/EventForm/rooms";
import _Catering from "@/src/ui/components/forms/EventForm/catering";
import _MeetingRooms from "@/src/ui/components/forms/EventForm/meeting-rooms";
import _Services from "@/src/ui/components/forms/EventForm/services";
import EventUtils from "@/src/core/app/utils/event";
import {useTranslation} from "next-i18next";
import {TFunction} from "react-i18next";
import {NetworkResponseError} from "@/src/common/network/NetworkResponse";
import FormUtils from "@/src/ui/utils/form";
import FormErrorUtils from "@/src/core/app/utils/form-error";
import {useAppInfo} from "@/src/ui/hooks/useAppInfo";
import EventRequestFormErrors from "@/src/core/app/domain/event/@types/FormErrorMap/EventRequestFormErrors";


const _Form = (props: _FormProps) => {
  const { t } = useTranslation();
  return (
    <Form className="py-3.75 space-y-7.5">
      <_Person {...props} />
      <_Event {...props} />
      <_Rooms {...props} />
      <_MeetingRooms {...props} />
      <_Catering {...props} />
      <_Services {...props} />
      <FormTermsAndConditions name='termsAndConditions' isRequired={true} />
      <FormRecaptcha name='recaptcha' />
      <div>
        <Button
          text={t('EventForm._Index.submit_button_label')}
          bold
          onClick={props.formik.handleSubmit}
          isActive={!props.formik.isSubmitting}
        />
      </div>
    </Form>
  );
}

const getInitialValues = (t: TFunction) : EventFormValues => {
  const groups = EventUtils.getCateringGroups();
  const cateringData: Record<string, CateringDataItem> = {};
  Object.keys(groups).forEach(key => {
    cateringData[key] = {
      people: '',
      days: '',
      from: '',
      to: '',
    };
  })
  return {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    iat: '',
    country: 'ES',

    eventType: '',
    eventName: '',
    eventDay: '',
    eventMonth: '',
    eventYear: '',
    eventPeople: '',
    eventHotelCode: '',
    eventDescription: '',

    individualRooms: '0',
    doubleRooms: '0',
    suiteRooms: '0',
    roomsAdditionalInfo: '',

    meetingRooms: '0',
    meetingRoomsData: [],
    meetingRoomsAdditionalInfo: '',

    servicesMultimedia: [],
    servicesOther: [],
    servicesAdditionalInfo: '',

    cateringData,
    cateringAdditionalInfo: '',

    recaptcha: '',
    termsAndConditions: false,
  }
};

const EventForm = (props: _Props) => {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const modalState = useModalProvider(state => state);
  let initialValues = getInitialValues(t);

  return (
    <Formik<EventFormValues>
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, formikHelpers) => {
        formikHelpers.setSubmitting(true);
        modalState.toggleModal(ModalKey.LOADER, {
          message: 'global.generic_loader.message'
        });

        const response = await EventUtils.sendEventRequestForm(appInfo, values);
        if (!response.isOk) {
          const networkError = response.error as NetworkResponseError;
          FormUtils.handleNetworkError({
            networkError,
            modalState,
            formikHelpers,
            t,
            formErrorMap: EventRequestFormErrors,
            logFn: (reason) => FormErrorUtils.logNetworkError('Event request form error', reason, networkError),
          });
          return;
        }
        formikHelpers.setValues(initialValues);
        modalState.toggleModal(ModalKey.NOTIFICATION, {
          type: T_NotificationType.SUCCESS,
          title: 'EventForm._Index.success_notification_title',
          message: 'EventForm._Index.success_notification_message',
        });
      }}
    >
      {(formik: FormikProps<EventFormValues>) => (
        <_Form
          formik={formik}
          countries={props.countries}
        />
      )}
    </Formik>
  );
}

export default EventForm;
