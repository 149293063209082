import {T_CheckoutHotel} from "@/src/core/app/domain/models/hotel/T_CheckoutHotel";
import AnalyticsUtils from "@/src/ui/utils/analytics";
import useEffectStrictMode from "@/src/ui/hooks/useEffectStrictMode";
import {
  T_ReservationBookingConfirmation
} from "@/src/core/app/domain/models/reservation/T_ReservationBookingConfirmation";

interface _Props {
  reservation: T_ReservationBookingConfirmation
  checkout: T_CheckoutHotel
}

const AnalyticsTransactionCart = (props: _Props) => {
  const cart = AnalyticsUtils.getBrowserCart(props.checkout);

  if (!cart) {
    return null;
  }

  useEffectStrictMode(() => {
    window.dataLayer.push({
      'event': 'transaction',
      'ecommerce': {
        'purchase': {
          'actionField': {
            'id': props.reservation.id,
            'affiliation': 'Online Store',
            'revenue': props.reservation.total / 100,
            'tax': Math.round(props.reservation.total * 0.21) / 100,
          },
          'products': cart,
        }
      }
    });
    window.dataLayer.push({
      'event': 'checkout',
      'ecommerce': {
        'checkout': {
          'actionField': {
            'step': 3
          },
          'products': []
        }
      }
    });
  });

  return null;
};

export default AnalyticsTransactionCart;
