import {
  T_ReservationBookingConfirmation
} from "@/src/core/app/domain/models/reservation/T_ReservationBookingConfirmation";
import CalendarUtils from "@/src/ui/utils/calendar";

const UiReservationUtils = {
  getNightsCount: (reservation: T_ReservationBookingConfirmation) => {
    return CalendarUtils.getDiffDays(reservation.start, reservation.end);
  },
  getAdultsCount: (reservation: T_ReservationBookingConfirmation) => {
    return reservation.rooms.reduce((acc, room) => acc + room.user_adults_count, 0);
  },
  getKidsCount: (reservation: T_ReservationBookingConfirmation) => {
    return reservation.rooms.reduce((acc, room) => acc + room.user_kids_count, 0);
  },
};

export default UiReservationUtils;
