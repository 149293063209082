import Button from "@/src/ui/components/generic/Button";
import TitledBox from "@/src/ui/components/generic/TitledBox";
import {UserData} from "@/src/core/app/domain/user/@types/Output/UserData";
import CountryUtils from "@/src/core/app/utils/country";
import {T_Country} from "@/src/core/app/domain/models/country/T_Country";
import LanguageUtils from "@/src/ui/utils/language";
import UserUtils from "@/src/core/app/utils/user";
import TitledBoxColumn from "@/src/ui/components/generic/TitledBoxColumn";
import {useState} from "react";
import UserEditForm from "@/src/ui/components/forms/UserEditForm";
import {CMS_Language} from "@/src/core/app/domain/cms/@types/language/CMS_Language";
import UserEditFormMobileComponent from "@/src/ui/components/forms/UserEditForm/mobile";
import {useTranslation} from "next-i18next";

interface _Props {
  userData: UserData
  countries: T_Country[]
  languages: CMS_Language[]
}

interface _InfoProps extends _Props {
  onOpen: VoidFunction
}

const _UserDataInfo = (props: _InfoProps) => {
  const { t } = useTranslation();
  const userData = props.userData;
  const country = CountryUtils.findByUuid(props.countries, userData.country);
  const language = LanguageUtils.getLanguage(props.languages, userData.langcode);

  return (
    <TitledBox title={t('BlockUserEditForm._UserDataInfo.title')}>
      <div className="space-y-5 p-3.75">
        <div className="space-y-3.75">
          <TitledBoxColumn title={t('BlockUserEditForm._UserDataInfo.first_name_label')} content={userData.firstName} />
          <TitledBoxColumn title={t('BlockUserEditForm._UserDataInfo.last_name_label')} content={userData.lastName} />
          <TitledBoxColumn title={t('BlockUserEditForm._UserDataInfo.email_label')} content={userData.email} />
          <TitledBoxColumn title={t('BlockUserEditForm._UserDataInfo.phone_number_label')} content={userData.phoneNumber} />
          <TitledBoxColumn title={t('BlockUserEditForm._UserDataInfo.gender_label')} content={t(UserUtils.getGenderLabel(userData.gender) as any)} />
          <TitledBoxColumn title={t('BlockUserEditForm._UserDataInfo.country_label')} content={country.title} />
          <TitledBoxColumn title={t('BlockUserEditForm._UserDataInfo.language_label')} content={language.label} />
        </div>
        <div>
          <Button text={t('BlockUserEditForm._UserDataInfo.edit_button_label')} bold style="secondary" onClick={props.onOpen} />
        </div>
      </div>
    </TitledBox>
  );
};

const _UserData = (props: _Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  if (isOpen) {
    return (
      <UserEditForm
        Component={UserEditFormMobileComponent}
        countries={props.countries}
        languages={props.languages}
        closeFn={toggle}
      />
    );
  }

  return (
    <_UserDataInfo {...props} onOpen={toggle}/>
  )
};

export default _UserData;
