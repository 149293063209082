import {AppInfo} from "@/src/core/app/domain/@types/AppInfo";
import {NetworkResponse} from "@/src/common/network/NetworkResponse";
import {locator} from "@/src/core/app/ioc";
import {TYPES} from "@/src/core/app/ioc/types";
import {ContactFormSuccessOutput} from "@/src/core/app/domain/contact/@types/Output/ContactFormSuccessOutput";
import {ContactFormErrorOutput} from "@/src/core/app/domain/contact/@types/Output/ContactFormErrorOutput";
import {FormsApiService} from "@/src/core/app/data/services/forms_api_service";
import CMS_AuthUtils from "@/src/core/app/domain/cms/utils/auth";

const ContactUtils = {
  getDepartments: () : Record<string, string> => ({
    'customer-service': 'contact_utils.department.customer-service',
    'sales': 'contact_utils.department.sales',
    'hr': 'contact_utils.department.hr',
    'marketing': 'contact_utils.department.marketing',
    'fidelity': 'contact_utils.department.fidelity',
  }),

  getDepartmentSelectOptions: () => {
    const items = ContactUtils.getDepartments();
    return Object.keys(items).map(key => ({
      label: items[key],
      value: key,
    }));
  },

  sendContactForm: async (
    appInfo: AppInfo,
    firstName: string,
    lastName: string,
    email: string,
    hotelCode: string,
    department: string,
    body: string,
  ) : Promise<NetworkResponse<ContactFormSuccessOutput, ContactFormErrorOutput>> => {
    const authHeaders = CMS_AuthUtils.getAuthHeaders();
    const api = locator.get<FormsApiService>(TYPES.FormsApiService);
    return await api.contactForm(appInfo, firstName, lastName, email, hotelCode, department, body, authHeaders);
  },
};

export default ContactUtils;
