import MaxWidthWrapper from "../../generic/MaxWidthWrapper";
import HeaderDesktopForm from "./HeaderDesktopForm";
import HeaderDesktopNavigation from "./HeaderDesktopNavigation";
import HeaderDesktopMenu from "./HeaderDesktopMenu";
import BgImg from "../../generic/BgImg";
import {
  HeaderDesktopContextProvider,
  useContextHeaderDesktopContext
} from "@/src/ui/context/HeaderDesktopContext";
import type {RouteProps} from "@/src/ui/@types/RouteProps";
import {useLayout} from "@/src/ui/hooks/useLayout";
import {LayoutType} from "@/src/ui/@types/layout/Layout";
import Anchor from "@/src/ui/components/generic/Anchor";
import {useTranslation} from "next-i18next";
import UrlUtils from "@/src/core/app/utils/url";
import {useAppInfo} from "@/src/ui/hooks/useAppInfo";
import CopyrightMessage from "@/src/ui/components/generic/CopyrightMessage";

const _HeaderDesktop = () => {
  const { t } = useTranslation();
  const context = useContextHeaderDesktopContext();
  const layout = useLayout();
  const appInfo = useAppInfo();

  return (
    <header className="sticky top-0 inset-x-0 z-30" id='he-layout-header-desktop'>
      <div className="absolute inset-x-0 h-29">
        <div className="p-5 h-full">
          <div className="absolute inset-0 bg-gradient-to-b from-brand-1-60 to-transparent"></div>
          {context?.isVisible && (
            <div className="absolute inset-0 bg-gray-20">
              {layout.type === LayoutType.HOME && (
                <BgImg imgRaw={{"src": "/assets/images/desktop/header-desktop-bg.jpg"}} top />
              )}
            </div>
          )}
          <MaxWidthWrapper>
            <div className="flex relative">
              <p className="sr-only">
                <CopyrightMessage />
              </p>
              <Anchor href={UrlUtils.getHomepageUrl(appInfo.langcode)}>
                <img
                  className="h-19 w-auto"
                  src="/assets/images/generic/elba-logo.svg"
                />
              </Anchor>
              <HeaderDesktopNavigation />
            </div>
          </MaxWidthWrapper>
        </div>
        <HeaderDesktopMenu />
        <HeaderDesktopForm />
      </div>
    </header>
  );
}

const HeaderDesktop = (props: RouteProps): JSX.Element => (
  <HeaderDesktopContextProvider layout={props.layout}>
    <_HeaderDesktop />
  </HeaderDesktopContextProvider>
);

export default HeaderDesktop;
