import useEffectStrictMode from "@/src/ui/hooks/useEffectStrictMode";
import {
  T_ReservationBookingConfirmation
} from "@/src/core/app/domain/models/reservation/T_ReservationBookingConfirmation";
import UiReservationUtils from "@/src/ui/utils/reservation";

interface _Props {
  reservation: T_ReservationBookingConfirmation
}

const DataLayerBookingConfirmation = (props: _Props) => {
  useEffectStrictMode(() => {
    const taxes = Math.round(props.reservation.total * 0.21);
    const pricesWithoutTaxes = props.reservation.total - taxes;
    window.dataLayer.push({
      'reservationId': props.reservation.id,
      'priceWithTaxes': props.reservation.total / 100,
      'taxes': taxes / 100,
      'priceWithoutTaxes': pricesWithoutTaxes / 100,
      'currency': 'EUR',
      'roomsCount': props.reservation.rooms.length,
      'checkIn': props.reservation.start,
      'checkOut': props.reservation.end,
      'nightsCount': UiReservationUtils.getNightsCount(props.reservation),
      'adultsCount': UiReservationUtils.getAdultsCount(props.reservation),
      'kidsCount': UiReservationUtils.getKidsCount(props.reservation),
    });
  });

  return null;
};

export default DataLayerBookingConfirmation;
