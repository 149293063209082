import type {RouteProps} from "@/src/ui/@types/RouteProps";
import {useEffectStrictMode} from "@front_web_mrmilu/hooks";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import SearchFormUtils from "@/src/core/app/utils/search-form";
import LanguageUtils from "@/src/ui/utils/language";
import NavigationUtils from "@/src/core/app/utils/navigation";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import BookingSearchSetAndRedirectUtils from "@/src/ui/utils/booking-search-set-and-redirect";

const Route_BookingSearchSetAndRedirect = (props: RouteProps) : JSX.Element => {
  const layoutData = useLayoutData<UI_LayoutGeneric>();
  const modalState = useModalProvider(state => state);

  useEffectStrictMode(() => {
    SearchFormUtils.showLoader(modalState);
    BookingSearchSetAndRedirectUtils.run(
      props.appInfo,
      props.route,
      layoutData
    );
  });

  return (
    <div className='h-100'>
      &nbsp;
    </div>
  );
}

export default Route_BookingSearchSetAndRedirect;
