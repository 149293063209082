import useEffectStrictMode from "@/src/ui/hooks/useEffectStrictMode";

interface _Props {
  hotelId: string
}

const DataLayerHotelId = (props: _Props) => {
  useEffectStrictMode(() => {
    window.dataLayer.push({
      'hotelId': props.hotelId
    });
  });

  return null;
};

export default DataLayerHotelId;
