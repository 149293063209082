import {CheckoutData} from "@/src/core/app/domain/models/checkout/CheckoutData";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import {SearchParams} from "@/src/core/app/domain/@types/SearchParams";
import {CheckoutStepRoomsData} from "@/src/core/app/domain/models/checkout/CheckoutStepRoomsData";
import {CheckoutStepRoomsStatus} from "@/src/core/app/domain/models/checkout/CheckoutStepRoomsStatus";
import {locator} from "@/src/core/app/ioc";
import {TYPES} from "@/src/core/app/ioc/types";
import {BookingApiService} from "@/src/core/app/data/services/booking_api_service";
import {GetAvailAndRatesParams} from "@/src/core/app/domain/booking/@types/Params/GetAvailAndRatesParams";
import {CheckoutRates} from "@/src/core/app/domain/models/checkout/CheckoutRates";
import {CheckoutRatesHotelRoom} from "@/src/core/app/domain/models/checkout/CheckoutRatesHotelRoom";
import {T_HotelRoomTeaser} from "@/src/core/app/domain/models/hotel-room/T_HotelRoomTeaser";
import {CheckoutRateRoomAndRoomTeaser} from "@/src/core/app/domain/models/checkout/CheckoutRateRoomAndRoomTeaser";
import {CheckoutRatesHotelRoomRateGroup} from "@/src/core/app/domain/models/checkout/CheckoutRatesHotelRoomRateGroup";
import {
  CheckoutRatesHotelRoomRateGroupBoard
} from "@/src/core/app/domain/models/checkout/CheckoutRatesHotelRoomRateGroupBoard";
import {CheckoutRoom} from "@/src/core/app/domain/@types/CheckoutRoom";
import Cookies from "js-cookie";
import {CookieCheckout} from "@/src/core/app/domain/@types/CookieCheckout";
import {CheckoutState} from "@/src/ui/view_models/checkout.state";
import UrlUtils from "@/src/core/app/utils/url";
import {SearchParamsOccupationRoom} from "@/src/core/app/domain/@types/SearchParamsOccupationRoom";
import {T_RateConfigurationPopup} from "@/src/core/app/domain/models/rate-configuration-popup/T_RateConfigurationPopup";
import {MakeBookingInput} from "@/src/core/app/domain/booking/@types/Input/MakeBookingInput";
import {CheckoutCustomerInfoFormValues} from "@/src/core/app/domain/forms/CheckoutCustomerInfoFormValues";
import {
  MakeBookingOutput, MakeBookingOutputPaymentMethodBapMethod,
  MakeBookingOutputPaymentMethodType
} from "@/src/core/app/domain/booking/@types/Output/MakeBookingOutput";
import {GetBookingTokenIframeInput} from "@/src/core/app/domain/booking/@types/Input/GetBookingTokenIframeInput";
import {BookingBapTokenInput} from "@/src/core/app/domain/booking/@types/Input/BookingBapTokenInput";
import {BookingBapKoInput} from "@/src/core/app/domain/booking/@types/Input/BookingBapKoInput";
import {UserData} from "@/src/core/app/domain/user/@types/Output/UserData";
import HotelRoomUtils from "@/src/core/app/utils/hotel-room";
import {CheckoutRatesHotel} from "@/src/core/app/domain/models/checkout/CheckoutRatesHotel";
import CMS_AuthUtils from "@/src/core/app/domain/cms/utils/auth";
import NavigationUtils from "@/src/core/app/utils/navigation";
import {SearchParamsContext} from "@/src/core/app/domain/@types/SearchParamsContext";
import {CheckoutStep} from "@/src/core/app/domain/models/checkout/CheckoutStep";

export const CHECKOUT_COOKIE_PREFIX = 'he_checkout';
export const CHECKOUT_COOKIE_DURATION = 365;
export const CHECKOUT_COOKIE_CURRENT_VERSION = '1.0.0';

const CheckoutUtils = {
  getDefaultValue: () : CheckoutData => ({
    searchParams: null,
    rooms: [],
  }),
  loadSearchParams: (context: SearchParamsContext) : SearchParams | null => {
    return SearchParamsUtils.load(context);
  },
  loadStepRoomsData: () : CheckoutStepRoomsData => {
    const url = new URL(window.location.href);
    const currentRoomIndex = url.searchParams.get('roomIndex');
    return {
      status: CheckoutStepRoomsStatus.LOADING,
      currentRoomIndex: currentRoomIndex ? parseInt(currentRoomIndex) : 0,
    }
  },
  getAvailAndRates: async (
    langcode: string,
    searchParams: SearchParams,
    currentRoomIndex: number
  ) => {
    const api = locator.get<BookingApiService>(TYPES.BookingApiService);
    const room = searchParams.rooms[currentRoomIndex];
    const params : GetAvailAndRatesParams = {
      hotel: searchParams.location?.id as string,
      date_from: searchParams.dateRange.start as string,
      date_to: searchParams.dateRange.end as string,
      adults_count: room.adults,
      kids_count: room.kids,
    }

    if (room.kids > 0) {
      params['kids_age'] = room.kid_ages.join(',');
    }

    if (searchParams.promocode !== '') {
      params['promocode'] = searchParams.promocode;
    }

    return await api.getAvailAndRates(langcode, params);
  },

  filterRatesByDingusCode: (rates: CheckoutRates, dingusCode: string) : CheckoutRates => {
    const ret : CheckoutRates = {
      hotels: []
    };
    rates.hotels.forEach(iHotel => {
      const hotel : CheckoutRatesHotel = {
        code: iHotel.code,
        uuid: iHotel.uuid,
        rooms: []
      };
      iHotel.rooms.forEach(iRoom => {
        const room = {
          code: iRoom.code,
          title: iRoom.title,
          uuid: iRoom.uuid,
          weight: iRoom.weight,
          rate_groups: iRoom.rate_groups.filter(iRateGroup => {
            return iRateGroup.code === `BAR+${dingusCode}`;
          }),
        };
        if (room.rate_groups.length > 0) {
          hotel.rooms.push(room);
        }
      });

      if (hotel.rooms.length > 0) {
        ret.hotels.push(hotel);
      }
    });

    return ret;
  },

  getCheckoutStepRoomsRateRoom: (rates: CheckoutRates, rooms: T_HotelRoomTeaser[]) : CheckoutRateRoomAndRoomTeaser[] => {
    const items : CheckoutRateRoomAndRoomTeaser[] = [];
    rates.hotels[0].rooms.forEach(rateRoom => {
      const roomTeaser = CheckoutUtils.getRoomTeaser(rateRoom, rooms);
      if (roomTeaser) {
        items.push({
          rateRoom,
          roomTeaser,
        })
      }
    });
    return items;
  },
  getRoomTeaser: (rateRoom: CheckoutRatesHotelRoom, rooms: T_HotelRoomTeaser[]) : T_HotelRoomTeaser | undefined => {
    return rooms.find(roomTeaser => roomTeaser.code === rateRoom.code);
  },
  getRoomLowestPrice: (room: CheckoutRatesHotelRoom) : number => {
    const prices = room.rate_groups.map(item => item.min_price);
    return Math.min(...prices);
  },
  getCheckoutRoom: (
    searchParamsRoom: SearchParamsOccupationRoom,
    room: CheckoutRateRoomAndRoomTeaser,
    rateGroup: CheckoutRatesHotelRoomRateGroup,
    board: CheckoutRatesHotelRoomRateGroupBoard
  ) : CheckoutRoom => {
    return {
      roomCode: room.rateRoom.code,
      rateGroupCode: rateGroup.code,
      boardCode: board.code,
      roomName: room.rateRoom.title,
      rateGroupName: rateGroup.title,
      boardTitle: board.name,
      price: board.price,
      isCancellable: board.is_cancellable,
      registrationRequired: rateGroup.registration_required,
      user_adults_count: searchParamsRoom.adults,
      user_kids_count: searchParamsRoom.kids,
      user_kids_age: searchParamsRoom.kid_ages,
      dingus_adults_count: board.adults_count,
      dingus_kids_count: board.kids_count,
    }
  },
  getCookieName: (hotelId: string) : string => {
    return `${CHECKOUT_COOKIE_PREFIX}_${hotelId}`;
  },
  getCookieData: (hotelId: string) : CookieCheckout => {
    const cookieName = CheckoutUtils.getCookieName(hotelId);
    const cookieValue = Cookies.get(cookieName);
    if (cookieValue === undefined) {
      return {
        data: {
          rooms: []
        },
        version: CHECKOUT_COOKIE_CURRENT_VERSION
      };
    }

    return JSON.parse(cookieValue) as CookieCheckout;
  },
  saveCookie: (state: CheckoutState) => {
    const cookieName = CheckoutUtils.getCookieName(state.hotelId);
    const cookieData = CheckoutUtils.getCookieData(state.hotelId);
    cookieData.data = {
      rooms: state.rooms,
    };
    Cookies.set(cookieName, JSON.stringify(cookieData), { expires: CHECKOUT_COOKIE_DURATION });
  },
  stepRoomsRedirect: (state: CheckoutState) => {
    const searchParams = state.searchParams as SearchParams;
    const stepData = state.currentStepData as CheckoutStepRoomsData;
    const nextRoomIndex = (stepData.currentRoomIndex + 1);
    if (searchParams.rooms.length !== nextRoomIndex) {
      const url = new URL(window.location.href);
      url.searchParams.set('roomIndex', nextRoomIndex.toString());
      window.location.href = url.toString();
      return;
    }
    if (state.stepExtrasEnabled) {
      window.location.href = UrlUtils.buildAppUrlWithLangPrefix(state.langcode, `/checkout/extras/${state.hotelId}`);
      return;
    }
    window.location.href = UrlUtils.buildAppUrlWithLangPrefix(state.langcode, `/checkout/customer-info/${state.hotelId}`);
    return;
  },
  getTotalAmountRooms: (rooms: CheckoutRoom[]) : number => {
    return rooms.reduce((accumulator, room) => accumulator + room.price, 0);
  },
  checkStepRoomsState: (state: CheckoutState) : boolean => {
    if (SearchParamsUtils.isEmpty(state.searchParams)) {
      return true;
    }

    const searchParams = state.searchParams as SearchParams;
    const stepData = state.currentStepData as CheckoutStepRoomsData;

    if ((stepData.currentRoomIndex + 1) > searchParams.rooms.length) {
      const url = new URL(window.location.href);
      window.location.href = UrlUtils.buildAppUrl(url.pathname);
      return false;
    }
    return true;
  },

  checkStepCustomerInformationState: (state: CheckoutState) : boolean => {
    let isOk = true;
    if (SearchParamsUtils.isEmpty(state.searchParams)) {
      isOk = false;
    }
    else {
      const searchParams = state.searchParams as SearchParams;
      if (searchParams.rooms.length != state.rooms.length) {
        isOk = false;
      }
    }

    if (!isOk) {
      NavigationUtils.goToHome(state.langcode);
      return false;
    }

    return true;
  },
  getSteps: (formExtrasEnabled: boolean) => {
    const parts = [
      "Elige habitación",
    ];
    if (formExtrasEnabled) {
      parts.push("Personaliza tu estancia")
    }
    parts.push("Confirma tu reserva");
    return parts;
  },
  getTotalAmount: (state: CheckoutState) : number => {
    // @TODO - Add extras
    return CheckoutUtils.getTotalAmountRooms(state.rooms);
  },
  getTotalAmountSplitted: (state: CheckoutState) : [number, number] => {
    const totalAmount : [number, number] = [0, 0];
    state.rooms.forEach(room => {
      if (room.isCancellable) {
        totalAmount[1] += room.price;
      }
      else {
        totalAmount[0] += room.price;
      }
    })
    return totalAmount;
  },
  splitRateGroupCode: (rateGroupCode: string) : [string, string | null] => {
    const parts : string[] = rateGroupCode.split('+');
    if (parts.length == 1) {
      return [parts[1], null];
    }
    return parts as [string, string];
  },
  getRatePopupsToDisplay: (state: CheckoutState, rateConfigurationPopupList: T_RateConfigurationPopup[]) : [number, number][] => {
    let items : any[] = [];
    state.rooms.forEach((room, roomIndex) => {
      rateConfigurationPopupList.forEach((rateConfigurationPopup, rateConfigurationPopupIndex) => {
        const [rateCode, promotionCode] = CheckoutUtils.splitRateGroupCode(room.rateGroupCode);
        if (promotionCode === null) {
          return;
        }
        if (rateConfigurationPopup.field_rate_codes.indexOf(promotionCode) !== -1) {
          items.push([
            roomIndex,
            rateConfigurationPopupIndex
          ]);
        }
      })
    })
    return items;
  },
  hasAnyRatePopupToDisplay: (state: CheckoutState, rateConfigurationPopupList: T_RateConfigurationPopup[]) : boolean => {
    return CheckoutUtils.getRatePopupsToDisplay(state, rateConfigurationPopupList).length > 0;
  },
  isRegistrationRequired: (userData: UserData | null, state: CheckoutState) : boolean => {
    if (userData !== null) {
      return false;
    }
    return !!state.rooms.find(room => room.registrationRequired);
  },
  areAllBoardsCancellable: (rateGroup: CheckoutRatesHotelRoomRateGroup) => {
    return rateGroup.boards.filter(board => !board.is_cancellable).length === 0;
  },
  areAllBoardsNotCancellable: (rateGroup: CheckoutRatesHotelRoomRateGroup) => {
    return rateGroup.boards.filter(board => board.is_cancellable).length === 0;
  },
  buildMakeBookingInput: (
    checkout: CheckoutState,
    values: CheckoutCustomerInfoFormValues,
    isRegistrationRequired: boolean,
    isPaymentDataRequired: boolean,
  ) : MakeBookingInput => {
    const searchParams = checkout.searchParams as SearchParams;
    const input : MakeBookingInput = {
      hotel: checkout.hotelCode,
      date_from: searchParams.dateRange.start as string,
      date_to: searchParams.dateRange.end  as string,
      promocode: searchParams.promocode,
      arrival_time: values.arrivalTime || '',
      rooms: checkout.rooms.map((room, roomIndex) => ({
        code: room.roomCode,
        rate: room.rateGroupCode,
        board: room.boardCode,
        amount: room.price,
        is_cancellable: room.isCancellable,
        registration_required: room.registrationRequired,
        dingus_adults_count: room.dingus_adults_count,
        dingus_kids_count: room.dingus_kids_count,
        user_adults_count: room.user_adults_count,
        user_kids_count: room.user_kids_count,
        user_kids_age: room.user_kids_age,
        comment: values[`specialRequestsRoom_${roomIndex}`],
      })),
      customer_info: {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        phone_number: values.phoneNumber,
        country: values.country,
      }
    };
    if (isRegistrationRequired) {
      input.customer_registration = {
        password: values.userPassword1 as string,
      };
    }
    if (isPaymentDataRequired) {
      input.payment_info = {
        first_name: values.ccFirstName as string,
        last_name: values.ccLastName as string,
        card_type: values.ccType as string,
        card_number: values.ccNumber as string,
        card_month: values.ccMonth as string,
        card_year: values.ccYear as string,
        card_cvv: values.ccCVV as string,
      }
    }
    return input;
  },
  makeBooking: async (
    langcode: string,
    input: MakeBookingInput
  ) => {
    const authHeaders = CMS_AuthUtils.getAuthHeaders();
    const api = locator.get<BookingApiService>(TYPES.BookingApiService);
    return await api.makeBooking(langcode, input, authHeaders);
  },
  goToBookingConfirmation: (
    langcode: string,
    reservationUuid: string
  ) => {
    window.location.href = UrlUtils.buildAppUrlWithLangPrefix(langcode, `/booking/confirmation/${reservationUuid}`);
  },
  goToBookingError: (
    langcode: string,
    reservationUuid: string
  ) => {
    window.location.href = UrlUtils.buildAppUrlWithLangPrefix(langcode, `/booking/error/${reservationUuid}`);
  },
  goToBookingPaymentIframe: (
    langcode: string,
    reservationUuid: string
  ) => {
    window.location.href = UrlUtils.buildAppUrlWithLangPrefix(langcode, `/booking/payment/iframe/${reservationUuid}`);
  },
  onMakeBookingOk: (
    langcode: string,
    output: MakeBookingOutput
  ) => {
    const type = output.payment_method.type;
    if (type === MakeBookingOutputPaymentMethodType.TAZZY) {
      CheckoutUtils.goToBookingConfirmation(langcode, output.reservation.uuid);
      return;
    }
    if (
      type === MakeBookingOutputPaymentMethodType.BAP &&
      output.payment_method.bapMethod === MakeBookingOutputPaymentMethodBapMethod.TOKEN
    ) {
      CheckoutUtils.goToBookingPaymentIframe(langcode, output.reservation.uuid);
      return;
    }
    window.location.href = output.payment_method.bapData?.url as string;
  },
  getBookingTokenIframe: async (
    langcode: string,
    input: GetBookingTokenIframeInput
  ) => {
    const authHeaders = CMS_AuthUtils.getAuthHeaders();
    const api = locator.get<BookingApiService>(TYPES.BookingApiService);
    return await api.getBookingTokenIframe(langcode, input, authHeaders);
  },
  bookingBapToken: async (
    langcode: string,
    input: BookingBapTokenInput
  ) => {
    const authHeaders = CMS_AuthUtils.getAuthHeaders();
    const api = locator.get<BookingApiService>(TYPES.BookingApiService);
    return await api.bookingBapToken(langcode, input, authHeaders);
  },
  bookingBapKo: async (
    langcode: string,
    input: BookingBapKoInput
  ) => {
    const authHeaders = CMS_AuthUtils.getAuthHeaders();
    const api = locator.get<BookingApiService>(TYPES.BookingApiService);
    return await api.bookingBapKo(langcode, input, authHeaders);
  },

  isRoomCodeInUrl: (roomCode: string) : boolean => {
    const url = new URL(window.location.href);
    const urlRoomCode = url.searchParams.get('roomCode') || '';
    return urlRoomCode === roomCode;
  },

  isCheckoutReadyToShowRooms: (checkoutState: CheckoutState) : boolean => {
    if (!checkoutState.isInitiated || SearchParamsUtils.isEmpty(checkoutState.searchParams) || checkoutState.currentStep !== CheckoutStep.ROOMS) {
      return false;
    }

    return true;
  },

  isCheckoutReadyToShowCalendar: (checkoutState: CheckoutState) : boolean => {
    if (!checkoutState.isInitiated || SearchParamsUtils.isEmpty(checkoutState.searchParams) || checkoutState.currentStep !== CheckoutStep.CALENDAR) {
      return false;
    }

    return true;
  },
};

export default CheckoutUtils;
