import {useRouteData} from "@/src/ui/hooks/useRouteData";
import {BookingConfirmationRouteProps} from "@/src/core/app/domain/route/@types/BookingConfirmationRouteProps";
import Button from "@/src/ui/components/generic/Button";
import Section from "@/src/ui/components/generic/Section/Section";
import BookingConfirmationSummaryHeader from "@/src/ui/components/checkout/generic/BookingConfirmationSummaryHeader";
import BookingConfirmationSummaryPrices from "@/src/ui/components/checkout/generic/BookingConfirmationSummaryPrices";
import BookingConfirmationSummaryRoom from "@/src/ui/components/checkout/mobile/BookingConfirmationSummaryRoom";
import BookingConfirmationSummaryReservation
  from "@/src/ui/components/checkout/generic/BookingConfirmationSummaryReservation";
import {useTranslation} from "next-i18next";
import {useAppInfo} from "@/src/ui/hooks/useAppInfo";
import UrlUtils from "@/src/core/app/utils/url";

const BookingConfirmationSummary = (): JSX.Element => {
  const { t } = useTranslation();
  const appInfo = useAppInfo();
  const routeData = useRouteData<BookingConfirmationRouteProps>();
  return (
    <Section paddingY="py-5" className="space-y-5">
      <BookingConfirmationSummaryHeader />
      <h2 className="title-4-mobile">{t('BookingConfirmationSummary._Index.summary_label')}</h2>
      <BookingConfirmationSummaryReservation />
      <ul className="space-y-5">
        {routeData.data.reservation.rooms.map((room, index) => {
          const roomTeaser = routeData.data.roomTeasers[room.room_id];
          return (
            <BookingConfirmationSummaryRoom
              key={index}
              room={room}
              roomTeaser={roomTeaser}
            />
          )
        })}
      </ul>
      <BookingConfirmationSummaryPrices />
      <div className="flex justify-center pt-2.5">
        <Button
          text={t('BookingConfirmationSummary._Index.back_to_home_link_label')}
          bold
          href={UrlUtils.getHomepageUrl(appInfo.langcode)}
        />
      </div>
    </Section>
  );
}

export default BookingConfirmationSummary;
