import {AnalyticsProps} from "@/src/ui/@types/analytics/AnalyticsProps";
import {DocumentProps} from "next/document";
import {AnalyticsCart} from "@/src/ui/@types/analytics/AnalyticsCart";
import {T_CheckoutHotel} from "@/src/core/app/domain/models/hotel/T_CheckoutHotel";
import CheckoutUtils from "@/src/core/app/utils/checkout";

const AnalyticsUtils = {
  getAnalyticsProps: (documentProps: DocumentProps) : AnalyticsProps | undefined => {
    const route = (documentProps as any).pageProps?.route;
    const routeData = (documentProps as any).pageProps?.routeData;

    if (!route || !routeData) {
      return undefined;
    }

    return {
      route,
      routeData,
    }
  },

  getCart: (analyticsProps: AnalyticsProps) : AnalyticsCart | undefined => {
    return undefined;
  },

  getBrowserCart: (checkout: T_CheckoutHotel) : AnalyticsCart | undefined => {
    const cookie = CheckoutUtils.getCookieData(checkout.id);
    if (cookie.data.rooms.length === 0) {
      return undefined;
    }

    return cookie.data.rooms.map(room => {
      const productId = [checkout.code, room.roomCode, room.rateGroupCode, room.boardCode].join('-');
      return {
        id: productId,
        name: room.rateGroupName,
        price: room.price / 100,
        brand: checkout.title,
        category: room.roomName,
        quantity: 1,
      }
    });
  },
};

export default AnalyticsUtils;
