import ModalSearchFormMobile from "./ModalSearchForm";
import { useModalProvider } from "@/src/ui/providers/modal.provider";
import ModalLoader from "src/ui/components/portals/generic/ModalLoader";
import ModalNotification from "src/ui/components/portals/generic/ModalNotification";
import {LayoutProps} from "@/src/ui/@types/layout/LayoutProps";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import ModalReservationCancel from "@/src/ui/components/portals/generic/ModalReservationCancel";
import ModalMobileKey from "@/src/ui/@types/modal/ModalMobileKey";
import ModalHotelRoomMobile from "src/ui/components/portals/mobile/ModalHotelRoom";
import ModalMultiGalleryMobile from "@/src/ui/components/portals/mobile/ModalMultiGallery";
import ModalLoungeMobile from "@/src/ui/components/portals/mobile/ModalLounge";
import ModalOfferFormByHotel from "@/src/ui/components/portals/generic/ModalOfferFormByHotel";
import ModalOfferFormByDestination from "@/src/ui/components/portals/generic/ModalOfferFormByDestination";
import ModalOfferFormByOfferGroup from "@/src/ui/components/portals/generic/ModalOfferFormByOfferGroup";
import ModalOfferFormByExperience from "@/src/ui/components/portals/generic/ModalOfferFormByExperience";
import ModalOfferSearchMenuMobile from "@/src/ui/components/portals/mobile/ModalOfferSearchMenu";
import ModalOfferFormDateRangeGeneric from "src/ui/components/portals/generic/ModalOfferFormDateRange";
import ModalOfferFormOccupationGeneric from "src/ui/components/portals/generic/ModalOfferFormOccupation";
import ModalOfferSearchDatesFilterMobile from "@/src/ui/components/portals/mobile/ModalOfferSearchDatesFilter";

type ModalComponent = () => JSX.Element | null;

const Map : Record<string, ModalComponent> = {
  [ModalKey.LOADER]: ModalLoader,
  [ModalKey.NOTIFICATION]: ModalNotification,
  [ModalKey.RESERVATION_CANCEL]: ModalReservationCancel,
  [ModalKey.HOTEL_ROOM]: ModalHotelRoomMobile,
  [ModalKey.MULTI_GALLERY]: ModalMultiGalleryMobile,
  [ModalKey.LOUNGE]: ModalLoungeMobile,
  [ModalKey.OFFER_FORM_BY_HOTEL]: ModalOfferFormByHotel,
  [ModalKey.OFFER_FORM_BY_DESTINATION]: ModalOfferFormByDestination,
  [ModalKey.OFFER_FORM_BY_OFFER_GROUP]: ModalOfferFormByOfferGroup,
  [ModalKey.OFFER_FORM_BY_EXPERIENCE]: ModalOfferFormByExperience,
  [ModalKey.OFFER_FORM_DATE_RANGE]: ModalOfferFormDateRangeGeneric,
  [ModalKey.OFFER_FORM_OCCUPATION]: ModalOfferFormOccupationGeneric,
  [ModalMobileKey.SEARCH_FORM]: ModalSearchFormMobile,
  [ModalMobileKey.OFFER_SEARCH_MENU]: ModalOfferSearchMenuMobile,
  [ModalMobileKey.OFFER_SEARCH_DATES_FILTER]: ModalOfferSearchDatesFilterMobile,
};

const _Portal = () => {
  const modalKey = useModalProvider(state => state.modalKey);

  if (modalKey === null) {
    return null;
  }
  const _Component = Map[modalKey];

  if (!_Component) {
    return null;
  }

  return (
    <_Component />
  )
};

const PortalsMobile = (props: LayoutProps) : JSX.Element => {
  const _RoutePortalsComponent = props.routePortals;
  return (
    <>
      {props.children}
      <_Portal />
      {_RoutePortalsComponent && (
        <_RoutePortalsComponent {...props.routeProps} />
      )}
    </>
  );
}

export default PortalsMobile;
