import {T_CheckoutHotel} from "@/src/core/app/domain/models/hotel/T_CheckoutHotel";
import AnalyticsUtils from "@/src/ui/utils/analytics";
import useEffectStrictMode from "@/src/ui/hooks/useEffectStrictMode";

interface _Props {
  step: number
  checkout: T_CheckoutHotel
}

const AnalyticsCheckoutCart = (props: _Props) => {
  const cart = AnalyticsUtils.getBrowserCart(props.checkout);

  if (!cart) {
    return null;
  }

  useEffectStrictMode(() => {
    window.dataLayer.push({
      'event': 'checkout',
      'ecommerce': {
        'checkout': {
          'actionField': {
            'step': props.step
          },
          'products': cart
        }
      }
    });
  });

  return null;
};

export default AnalyticsCheckoutCart;
