import MaxWidthWrapper from "../../generic/MaxWidthWrapper";
import Title3Menu from "../../generic/Title3Menu";
import HotelLinkList from "../../hotel/HotelLinkList";
import {useContextHeaderDesktopContext} from "@/src/ui/context/HeaderDesktopContext";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import {useRouteProps} from "@/src/ui/hooks/useRouteProps";
import {CMS_MenuItem} from "@/src/core/app/domain/cms/@types/menu/CMS_MenuItem";
import {T_Destination} from "@/src/core/app/domain/models/destination/T_Destination";
import {CMS_Menu} from "@/src/core/app/domain/cms/@types/menu/CMS_Menu";
import {T_HotelTree} from "@/src/core/app/domain/models/hotel-tree/T_HotelTree";
import Anchor from "@/src/ui/components/generic/Anchor";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutBase} from "@/src/ui/@types/layout/UI_LayoutBase";
import {useTranslation} from "next-i18next";
import {useEffect, useRef} from "react";

const _ArrowLink = ({menuItem}: {menuItem: CMS_MenuItem}) => (
  <Anchor className="text-sm font-bold text-brand-2 flex items-center space-x-2.5" href={menuItem.url}>
    <span>{menuItem.title}</span>
    <img
      className="h-3.5 w-auto"
      src="/assets/icons/chevron-right-brand-2.svg"
      alt=""
    />
  </Anchor>
);

const _DestinationMenu = ({destinations} : {destinations: T_Destination[]}) => {
  const {t} = useTranslation();
  const layoutData = useLayoutData<UI_LayoutBase>();
  return (
    <div className="bg-gray-10 pr-5 pt-12.5 flex-1 max-w-45">
      <Anchor href={layoutData.sitePageUrls.destination_list_page_url}>
        <Title3Menu hasArrow>
          {t('HeaderDesktopMenu._DestinationMenu.destination_label')}
        </Title3Menu>
      </Anchor>
      <ul className="ul pt-5 space-y-4 leading-4">
        {destinations.map((item, index) => (
          <li className="hover:underline" key={item.id}>
            <Anchor href={item.url}>{item.destinationName} ({item.hotelCount})</Anchor>
          </li>
        ))}
      </ul>
    </div>
  )
}

const _PopupMenu = ({menuPopup} : {menuPopup: CMS_Menu}) => (
  <div className="flex-initial w-72 pl-5 pt-12.5 bg-white">
    {menuPopup.items.map((item, index) => (
      <div key={index}>
        <_ArrowLink menuItem={item} />
        {item.below.length > 0 && (
          <ul className="ul pt-5 space-y-4 leading-4">
            {item.below.map((item, index) => (
              <li className="hover:underline" key={item.id}>
                <Anchor href={item.url}>{item.title}</Anchor>
              </li>
            ))}
          </ul>
        )}
        {(index + 1) != menuPopup.items.length && (
          <div className="h-7 5" />
        )}
      </div>
    ))}
  </div>
)

const _HotelMenu = ({hotelTree} : {hotelTree: T_HotelTree}) => (
  <div className="bg-white flex-1 w-full">
    <div className="bg-white pt-12.5 flex flex-col flex-wrap items-center w-full h-175">
      {hotelTree.regions.map((item) => (
        <div key={item.id} className="w-75 flex-none">
          <HotelLinkList region={item} />
        </div>
      ))}
    </div>
  </div>
)

const HeaderDesktopMenu = (): JSX.Element | null => {
  const ref = useRef(null);
  const context = useContextHeaderDesktopContext();
  const routeProps = useRouteProps<any, UI_LayoutGeneric>();
  const isVisible = context?.isMenuVisible || false;

  useEffect(() => {
    const $html = document.querySelector('html') as HTMLElement;
    if (!isVisible || !ref.current) {
      $html.classList.remove('has-modal');
      return;
    }
    const $el = ref.current as HTMLElement;
    const rect = $el.getBoundingClientRect();

    if (window.innerHeight >= (rect.height + rect.top)) {
      return;
    }

    const maxHeight = window.innerHeight - rect.top;
    $el.style.maxHeight = `${maxHeight}px`;
    $el.style.overflowY = 'auto';
    $html.classList.add('has-modal');
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="relative z-10">
      <div className="absolute inset-x-0 border-t border-b border-gray-30">
        <div className="absolute inset-0 flex bg-white">
          <div className="flex-1 bg-gray-10" />
          <div className="flex-1" />
        </div>
        <nav ref={ref} className="relative px-5 text-sm text-brand-2">
          <MaxWidthWrapper className='relative'>
            <div className="flex items-stretch divide-x divide-gray-30">
              <_DestinationMenu destinations={routeProps.layoutData.destinations}/>
              <_HotelMenu hotelTree={routeProps.layoutData.hotelTree}/>
              <_PopupMenu menuPopup={routeProps.layoutData.menuPopup}/>
            </div>
            <div
              className='absolute top-0 right-0 p-5 cursor-pointer'
              onClick={() => context?.toggleMenu()}
            >
              <img
                src="/assets/icons/icon-close.svg"
              />
            </div>
          </MaxWidthWrapper>
        </nav>
      </div>
    </div>
  );
}

export default HeaderDesktopMenu;
