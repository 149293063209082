import Paragraph from "@/src/ui/components/generic/Paragraph";
import CalendarUtils from "@/src/ui/utils/calendar";
import {T_OfferTeaser} from "@/src/core/app/domain/models/offer/T_OfferTeaser";
import {useTranslation} from "next-i18next";

interface _Props {
  item: T_OfferTeaser
}

const _Body = (props: _Props) => {
  const {t} = useTranslation();
  return (
    <div className='pt-3'>
      {props.item.field_subtitle && (
        <p className='pb-1'>{props.item.field_subtitle}</p>
      )}
      <Paragraph
        size='medium'
        className="text-brand-2-60 whitespace-pre-line"
      >
        <span>{t('OfferDetail._PriceTagRange.from_label')} {CalendarUtils.formatDate(props.item.datetime_from)}</span>
        {props.item.datetime_to && (
          <span> {t('OfferDetail._PriceTagRange.to_label').toLowerCase()}  {CalendarUtils.formatDate(props.item.datetime_to)}</span>
        )}
      </Paragraph>
      <meta itemProp="startDate" content={CalendarUtils.formatDate(props.item.datetime_from, 'yyyy-MM-dd HH:mm:ss')} />
      {props.item.datetime_to && (
        <meta itemProp="endDate" content={CalendarUtils.formatDate(props.item.datetime_to, 'yyyy-MM-dd HH:mm:ss')} />
      )}
    </div>
  );
}

export default _Body;