import {RouteProps} from "@/src/ui/@types/RouteProps";
import {BookingPaymentIframeRouteProps} from "@/src/core/app/domain/route/@types/BookingPaymentIframeRouteProps";
import useEffectStrictMode from "@/src/ui/hooks/useEffectStrictMode";
import CheckoutUtils from "@/src/core/app/utils/checkout";
import Checkout from "@/src/core/app/utils/checkout";
import SentryUtils from "@/src/common/utils/sentry";
import {NetworkResponseError} from "@/src/common/network/NetworkResponse";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import DataLayerHotelId from "@/src/ui/components/analytics/browser/DataLayerHotelId";
import React from "react";

const checkOrigin = (origin: string, iframeUrl: string) : boolean => {
  const url = new URL(iframeUrl);
  const urlString = `${url.protocol}//${url.host}`;
  return origin === urlString;
};

const Route_BookingPaymentIframe = (props: RouteProps<BookingPaymentIframeRouteProps>) => {
  const modal = useModalProvider(state => state);

  const messageListener = async (param: MessageEvent) => {
    const reservationId = props.routeData.data.reservation.id;
    if (!checkOrigin(param.origin, props.routeData.data.iframeUrl)) {
      return;
    }

    if (param.data.result === 'SUCCESS') {
      window.removeEventListener('message', messageListener);

      modal.toggleModal(ModalKey.LOADER);
      const response = await CheckoutUtils.bookingBapToken(
        props.appInfo.langcode,
        {
          reservation: reservationId,
          tkn: param.data.tkn,
        }
      );
      if (response.isOk) {
        return CheckoutUtils.goToBookingConfirmation(props.appInfo.langcode, reservationId);
      }
      SentryUtils.logNetworkError('BAP token SUCCESS - API failed', response.error as NetworkResponseError);
      return Checkout.goToBookingError(props.appInfo.langcode, reservationId);
    }
    if (param.data.result === 'FAILED') {
      window.removeEventListener('message', messageListener);
      SentryUtils.captureMessage('BAP token FAILED failed', {
        extra: {
          data: param.data
        },
      });

      modal.toggleModal(ModalKey.LOADER);
      const response = await CheckoutUtils.bookingBapKo(
        props.appInfo.langcode,
        {
          reservation: reservationId,
          msg: param.data.msg,
        }
      );
      if (!response.isOk) {
        SentryUtils.logNetworkError('BAP token FAILED - API failed', response.error as NetworkResponseError);
      }
      return Checkout.goToBookingError(props.appInfo.langcode, reservationId);
    }
  };

  useEffectStrictMode(() => {
    window.addEventListener('message', messageListener);
  });

  return (
    <div className='w-full h-screen bg-white'>
      <iframe
        className='w-full h-full'
        src={props.routeData.data.iframeUrl}
      />
      <DataLayerHotelId hotelId={props.routeData.data.hotel.code} />
    </div>
  );
};

export default Route_BookingPaymentIframe;
