import Button from "@/src/ui/components/generic/Button";
import {FormikProps} from "formik/dist/types";
import {useModalProvider} from "@/src/ui/providers/modal.provider";
import {Form, Formik} from "formik";
import TitledBox from "@/src/ui/components/generic/TitledBox";
import FormRecaptcha from "@/src/ui/components/formik/FormRecaptcha";
import FormTermsAndConditions from "@/src/ui/components/formik/FormTermsAndConditions";
import FormUtils from "@/src/ui/utils/form";
import ModalKey from "@/src/ui/@types/modal/ModalKey";
import {T_NotificationType} from "@/src/ui/@types/T_Notification";
import TimerUtils from "@/src/core/app/utils/timer";
import FormInputText from "@/src/ui/components/formik/FormInputText";
import VerisignImg from "@/src/ui/components/generic/VerisignImg";
import FormInputSelect from "@/src/ui/components/formik/FormInputSelect";
import HotelTreeUtils from "@/src/ui/utils/hotel-tree";
import {useLayoutData} from "@/src/ui/hooks/useLayoutData";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import {ContactFormValues} from "@/src/core/app/domain/forms/ContactFormValues";
import FormInputTextarea from "@/src/ui/components/formik/FormInputTextarea";
import ContactUtils from "@/src/core/app/utils/contact";
import {useDevice} from "@/src/ui/hooks/useDevice";
import cn from "classnames";
import {useTranslation} from "next-i18next";
import I18nUtils from "@/src/ui/utils/i18n";
import {useAppInfo} from "@/src/ui/hooks/useAppInfo";
import {NetworkResponseError} from "@/src/common/network/NetworkResponse";
import FormErrorUtils from "@/src/core/app/utils/form-error";
import ContactFormErrors from "@/src/core/app/domain/contact/@types/FormErrorMap/ContactFormErrors";

interface _FormProps {
  formik: FormikProps<ContactFormValues>
}

interface _Props {
}

const _Form = (props: _FormProps) => {
  const { t } = useTranslation();
  const device = useDevice();
  const layoutData = useLayoutData<UI_LayoutGeneric>();
  const hotels = HotelTreeUtils.getHotels(layoutData.hotelTree);

  return (
    <TitledBox title={t('ContactForm._Index.title')}>
      <Form
        className={cn(
          device.isDesktop && "px-5 py-3.75 space-y-7.5",
          device.isMobile && "px-5 py-3.75 space-y-2.5",
        )}
      >
        <div
          className={cn(
            device.isDesktop && "grid grid-cols-3 gap-5",
            device.isMobile && "space-y-2.5",
          )}
        >
          <FormInputText
            label={t('ContactForm._Index.first_name_label') + " *"}
            name="firstName"
            validate={FormUtils.validateIsRequired}
          />
          <FormInputText
            label={t('ContactForm._Index.last_name_label') + " *"}
            name="lastName"
            validate={FormUtils.validateIsRequired}
          />
          <FormInputText
            label={t('ContactForm._Index.email_label') + " *"}
            name="email"
            validations={[FormUtils.validateIsRequired, FormUtils.validateIsEmail]}
          />
        </div>
        <div
          className={cn(
            device.isDesktop && "flex space-x-5",
            device.isMobile && "space-y-2.5",
          )}
        >
          <FormInputSelect
            label={t('ContactForm._Index.hotel_label') + " *"}
            name='hotelCode'
            emptyOption={'-' + t('ContactForm._Index.hotel_empty_option') + " -"}
            options={HotelTreeUtils.getSelectOptions(hotels)}
            validate={FormUtils.validateIsRequired}
          />
          <FormInputSelect
            label={t('ContactForm._Index.department_label') + " *"}
            name='department'
            emptyOption={'-' + t('ContactForm._Index.department_empty_option') + " -"}
            options={I18nUtils.translateFormInputSelectOptions(
              ContactUtils.getDepartmentSelectOptions(), t
            )}
            validate={FormUtils.validateIsRequired}
          />
        </div>
        <div>
          <FormInputTextarea
            name='body'
            label={t('ContactForm._Index.body_label')}
            validate={FormUtils.validateIsRequired}
          />
        </div>
        <FormTermsAndConditions name='termsAndConditions' isRequired={true} />
        <FormRecaptcha name='recaptcha' />
        <div>
          <Button
            text={t('ContactForm._Index.submit_button_label')}
            bold
            onClick={props.formik.handleSubmit}
            isActive={!props.formik.isSubmitting}
          />
        </div>
        <VerisignImg />
      </Form>
    </TitledBox>
  );
}

const ContactForm = (props: _Props) => {
  const {t} = useTranslation();
  const appInfo = useAppInfo();
  const modalState = useModalProvider(state => state);
  let initialValues : ContactFormValues = {
    firstName: '',
    lastName: '',
    email: '',
    hotelCode: '',
    department: '',
    body: '',
    recaptcha: '',
    termsAndConditions: false,
  };

  return (
    <Formik<ContactFormValues>
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, formikHelpers) => {
        formikHelpers.setSubmitting(true);
        modalState.toggleModal(ModalKey.LOADER, {
          message: 'global.generic_loader.message'
        });

        await TimerUtils.sleep(2000);

        const response = await ContactUtils.sendContactForm(
          appInfo,
          values.firstName,
          values.lastName,
          values.email,
          values.hotelCode,
          values.department,
          values.body,
        );
        if (!response.isOk) {
          const networkError = response.error as NetworkResponseError;
          FormUtils.handleNetworkError({
            networkError,
            modalState,
            formikHelpers,
            t,
            formErrorMap: ContactFormErrors,
            logFn: (reason) => FormErrorUtils.logNetworkError('Contact form error', reason, networkError),
          });
          return;
        }

        formikHelpers.setValues(initialValues);
        modalState.toggleModal(ModalKey.NOTIFICATION, {
          type: T_NotificationType.SUCCESS,
          title: 'ContactForm._Index.success_notification_title',
          message: 'ContactForm._Index.success_notification_message',
        });
      }}
    >
      {(formik: FormikProps<ContactFormValues>) => (
        <_Form
          formik={formik}
        />
      )}
    </Formik>
  );
}

export default ContactForm;
