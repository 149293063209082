import {Route} from "@/src/core/app/domain/@types/Route";
import LanguageUtils from "@/src/ui/utils/language";
import {AppInfo} from "@/src/core/app/domain/@types/AppInfo";
import {UI_LayoutGeneric} from "@/src/ui/@types/layout/UI_LayoutGeneric";
import CurrencyUtils from "@/src/core/app/utils/currency";
import HotelTreeUtils from "@/src/ui/utils/hotel-tree";
import CalendarUtils from "@/src/ui/utils/calendar";
import {SearchParamsOccupationRoom} from "@/src/core/app/domain/@types/SearchParamsOccupationRoom";
import SearchParamsUtils from "@/src/core/app/utils/search-params";
import {SearchParams} from "@/src/core/app/domain/@types/SearchParams";
import {SearchParamsLocationType} from "@/src/core/app/domain/@types/SearchParamsLocationType";
import {SearchParamsContext} from "@/src/core/app/domain/@types/SearchParamsContext";
import NavigationUtils from "@/src/core/app/utils/navigation";
import SentryUtils from "@/src/common/utils/sentry";

const BookingSearchSetAndRedirectUtils = {
  run: (appInfo: AppInfo, route: Route, layoutData: UI_LayoutGeneric) => {
    try {
      const hotel = BookingSearchSetAndRedirectUtils.getHotel(route, layoutData);
      const data = {
        langcode: BookingSearchSetAndRedirectUtils.getLangcode(route, layoutData),
        currency: BookingSearchSetAndRedirectUtils.getCurrencyCode(route, layoutData),
        hotelCode: hotel.code,
        from: BookingSearchSetAndRedirectUtils.getDateFrom(route),
        to: BookingSearchSetAndRedirectUtils.getDateTo(route),
        rooms: BookingSearchSetAndRedirectUtils.getRooms(route),
        promocode: BookingSearchSetAndRedirectUtils.getParam(route, 'promocode', false, ''),
      }

      if (CalendarUtils.getDiffDays(data.from, data.to) <= 0) {
        throw new Error('Invalid date range');
      }

      const searchParams : SearchParams = {
        location: {
          type: SearchParamsLocationType.HOTEL,
          id: hotel.code,
        },
        rooms: data.rooms,
        dateRange: {
          start: data.from,
          end: data.to,
        },
        promocode: data.promocode,
      };
      const context : SearchParamsContext = {
        hotelTree: layoutData.hotelTree,
        currentHotelCode: null,
      };
      CurrencyUtils.setCurrencyCodeCookie(data.currency);
      SearchParamsUtils.submit(searchParams, context);
    }
    catch (e) {
      SentryUtils.captureMessage('BookingSearchSetAndRedirectUtils invalid params');
      NavigationUtils.goToHome(appInfo.langcode)
    }
  },

  getParam: (route: Route, key: string, isRequired: boolean = true, defaultValue: string = '') => {
    const queryParams = route.queryParams;
    if (queryParams[key] === undefined) {
      if (isRequired) {
        throw new Error('Missing required parameter: ' + key);
      }
      return defaultValue;
    }
    return queryParams[key] as string;
  },

  getIntegerParam: (route: Route, key: string, isRequired: boolean = true, defaultValue: string = '') => {
    const value = BookingSearchSetAndRedirectUtils.getParam(route, key, isRequired, defaultValue);
    return BookingSearchSetAndRedirectUtils.parseInt(value, key);
  },

  parseInt: (value: string, key: string) : number => {
    const iValue = parseInt(value);

    if (isNaN(iValue)) {
      throw new Error('Invalid integer parameter: ' + key);
    }
    return iValue;
  },

  getLangcode: (route: Route, layoutData: UI_LayoutGeneric) => {
    const code = BookingSearchSetAndRedirectUtils.getParam(route, 'language', false, 'es');
    if (!LanguageUtils.isValidLangcode(layoutData.languages, code)) {
      throw new Error('Invalid langcode');
    }
    return code;
  },

  getCurrencyCode: (route: Route, layoutData: UI_LayoutGeneric) => {
    const code = BookingSearchSetAndRedirectUtils.getParam(route, 'currency', false, 'EUR');
    if (!CurrencyUtils.isValid(code, layoutData.currencies)) {
      throw new Error('Invalid currency code');
    }
    return code;
  },

  getHotel: (route: Route, layoutData: UI_LayoutGeneric) => {
    const code = BookingSearchSetAndRedirectUtils.getParam(route, 'hotel');
    return HotelTreeUtils.getHotel(code, layoutData.hotelTree);
  },

  validateDate: (day: string) => {
    if (!CalendarUtils.isValidDay(day)) {
      throw new Error('Invalid date: ' + day);
    }
    if (CalendarUtils.isBeforeToday(day)) {
      throw new Error('Invalid date (is before today): ' + day);
    }
  },

  getDateFrom: (route: Route) =>  {
    const value = BookingSearchSetAndRedirectUtils.getParam(route, 'from');
    BookingSearchSetAndRedirectUtils.validateDate(value);
    return value;
  },

  getDateTo: (route: Route) =>  {
    const value = BookingSearchSetAndRedirectUtils.getParam(route, 'to');
    BookingSearchSetAndRedirectUtils.validateDate(value);
    return value;
  },

  getRooms(route: Route): SearchParamsOccupationRoom[] {
    const rooms = [];
    const roomCount = BookingSearchSetAndRedirectUtils.getIntegerParam(route, 'room_count');
    if (roomCount < 1 || roomCount > 5) {
      throw new Error('Room count must be between 1 and 5');
    }
    console.log('roomCount', roomCount);
    for (let i = 0; i < roomCount; i++) {
      const roomData = BookingSearchSetAndRedirectUtils.getParam(route, `room_${i}`);
      const parts = roomData.split(',');
      if (parts.length === 0) {
        throw new Error('Room count must be between 1 and 5');
      }
      const room : SearchParamsOccupationRoom = {
        adults: 2,
        kids: 0,
        kid_ages: [],
      };
      const adults = BookingSearchSetAndRedirectUtils.parseInt(parts[0], `room_${i}_adults`);
      if (adults < 0 || adults > 5) {
        throw new Error('Adults count must be between 0 and 5');
      }
      room.adults = adults;
      const childrenCount = parts.length - 1;
      for (let j = 0; j < childrenCount; j++) {
        const kidAge = BookingSearchSetAndRedirectUtils.parseInt(parts[j + 1], `room_${i}_kid_${j}`);
        if (kidAge < 0 || kidAge > 11) {
          throw new Error('Kids age must be between 0 and 11');
        }
        room.kids++;
        room.kid_ages.push(kidAge);
      }

      rooms.push(room);
    }

    return rooms;
  }
};

export default BookingSearchSetAndRedirectUtils;
