import {RouteProps} from "@/src/ui/@types/RouteProps";
import {T_HotelSection} from "@/src/core/app/domain/models/hotel-section/T_HotelSection";
import HotelSectionDesktop from "@/src/ui/components/routes/Route_HotelSection/desktop";
import HotelSectionMobile from "@/src/ui/components/routes/Route_HotelSection/mobile";
import DataLayerHotelId from "@/src/ui/components/analytics/browser/DataLayerHotelId";

export default function HotelSectionDefault(props: RouteProps<T_HotelSection>): JSX.Element {
  const device = props.device;
  const _Component = device.isDesktop ? HotelSectionDesktop : HotelSectionMobile;

  return (
    <>
      <_Component {...props} />
      <DataLayerHotelId hotelId={props.routeData.data.ui_hotel_common_info.code} />
    </>
  )
}
