import BookingConfirmationSummaryHeader from "@/src/ui/components/checkout/generic/BookingConfirmationSummaryHeader";
import BookingConfirmationSummaryReservation
  from "@/src/ui/components/checkout/generic/BookingConfirmationSummaryReservation";
import BookingConfirmationSummaryPrices from "@/src/ui/components/checkout/generic/BookingConfirmationSummaryPrices";
import {useRouteData} from "@/src/ui/hooks/useRouteData";
import {BookingConfirmationRouteProps} from "@/src/core/app/domain/route/@types/BookingConfirmationRouteProps";
import BookingConfirmationSummaryRoom from "@/src/ui/components/checkout/desktop/BookingConfirmationSummaryRoom";
import Button from "@/src/ui/components/generic/Button";
import {useTranslation} from "next-i18next";
import {useAppInfo} from "@/src/ui/hooks/useAppInfo";
import UrlUtils from "@/src/core/app/utils/url";

const BookingConfirmationSummary = (): JSX.Element => {
  const { t } = useTranslation();
  const appInfo = useAppInfo();
  const routeData = useRouteData<BookingConfirmationRouteProps>();
  return (
    <div className="space-y-10">
      <BookingConfirmationSummaryHeader />
      <div className="space-y-5">
        <h2 className="title-4">{t('BookingManageRoom._Index.subtotal_label')}</h2>
        <BookingConfirmationSummaryReservation />
        <ul className="space-y-5">
          {routeData.data.reservation.rooms.map((room, index) => {
            const roomTeaser = routeData.data.roomTeasers[room.room_id];
            return (
              <BookingConfirmationSummaryRoom
                key={index}
                room={room}
                roomTeaser={roomTeaser}
              />
            )
          })}
        </ul>
        <BookingConfirmationSummaryPrices />
      </div>
      <div className="flex justify-end">
        <Button
          text={t('BookingConfirmationSummary._Index.back_to_home_link_label')}
          bold
          href={UrlUtils.getHomepageUrl(appInfo.langcode)}
        />
      </div>
    </div>
  );
}

export default BookingConfirmationSummary;
