import {T_ReservationRoom} from "@/src/core/app/domain/models/reservation/T_ReservationRoom";
import SearchParamsFormatter from "@/src/core/app/formatter/search-params";
import {useCurrencyConfig} from "@/src/ui/hooks/useCurrencyConfig";
import UiCurrencyUtils from "@/src/ui/utils/currency";
import {T_HotelRoomTeaser} from "@/src/core/app/domain/models/hotel-room/T_HotelRoomTeaser";
import UiMediaUtils from "@/src/ui/utils/media";
import HotelRoomUtils from "@/src/core/app/utils/hotel-room";
import {useTranslation} from "next-i18next";

interface _Props {
  room: T_ReservationRoom
  roomTeaser: T_HotelRoomTeaser
}

const BookingConfirmationSummaryRoom = (props: _Props) => {
  const { t } = useTranslation();
  const currencyConfig = useCurrencyConfig();
  const room = props.room;

  return (
    <li className="flex space-x-5">
      <figure className="w-35 flex-none">
        <img
          className="w-full h-auto"
          {...UiMediaUtils.getImgPropsFromMediaImage(props.roomTeaser.field_room_media_square)}
        />
      </figure>
      <div className="flex-1 p-5 border border-gray-20 space-y-3.5 text-sm text-brand-2">
        <h3 className="text-big-bold">{HotelRoomUtils.getTeaserTitle(props.roomTeaser)}</h3>
        <p>
          {SearchParamsFormatter.formatRoomOccupation(room.user_adults_count, room.user_kids_count, t)}
        </p>
        <div className="space-y-1">
          <p>
            <span className="font-bold text-brand-2-60">{t('BookingConfirmationSummaryRoom._Index.rate_label')}: </span>{" "}
            {room.rate_group_description}
          </p>
          <div className="flex justify-between">
            <div className="flex-1">
              <span className="font-bold text-brand-2-60">{t('BookingConfirmationSummaryRoom._Index.board_label')}: </span>{" "}
              {room.board_description}
            </div>
            {/*<div className="flex-none">*/}
            {/*  <strong>{regimePrice}</strong>*/}
            {/*</div>*/}
          </div>
          {/*<p>*/}
          {/*      <span className="font-bold text-brand-2-60">*/}
          {/*        Tipo de cama:{" "}*/}
          {/*      </span>{" "}*/}
          {/*  {bedType}*/}
          {/*</p>*/}
          {/*<div className="flex">*/}
          {/*  <div className="flex-none bold text-brand-2-60">*/}
          {/*    Extras:&nbsp;*/}
          {/*  </div>*/}
          {/*  <ul className="flex-1">*/}
          {/*    {extras.map(({ label, price }) => (*/}
          {/*      <li key={label} className="flex justify-between">*/}
          {/*        <span>{label}</span>*/}
          {/*        <strong>{price}</strong>*/}
          {/*      </li>*/}
          {/*    ))}*/}
          {/*  </ul>*/}
          {/*</div>*/}
        </div>
        <p className="text-right">
          <span className="text-sm font-bold uppercase text-brand-2-60">
            {t('BookingConfirmationSummaryRoom._Index.room_subtotal_label')}:&nbsp;
          </span>
          <strong className="text-big-bold">
            {UiCurrencyUtils.format(room.total, currencyConfig)}
          </strong>
        </p>
      </div>
    </li>
  )
}

export default BookingConfirmationSummaryRoom;
