import { immer } from "zustand/middleware/immer";
import BrowserUtils from "@/src/ui/utils/browser";
import {AppInitiatedEvent} from "@/src/ui/@types/event/AppInitiatedEvent";
import {UserData} from "@/src/core/app/domain/user/@types/Output/UserData";
import {UserTmsInfoState} from "@/src/ui/view_models/user-tms-info.state";
import UserTmsInfoUtils from "@/src/core/app/utils/user-tms-info";
import {createStore, useStore} from "zustand";
import {AppInfo} from "@/src/core/app/domain/@types/AppInfo";

export const useUserTmsInfoStore = createStore<UserTmsInfoState>()(immer((set, get) => {
  BrowserUtils.onAppInitiated<AppInitiatedEvent>((event) => {
    get().init(event.detail.userData, event.detail.appInfo);
  });

  return {
    isLoading: true,
    data: null,
    init: async (userData: UserData | null, appInfo: AppInfo) => {
      if (userData === null) {
        set(state => {
          state.isLoading = false;
        });
        return;
      }

      const data = await UserTmsInfoUtils.getTmsInfo(appInfo);
      set(state => {
        state.isLoading = false;
        state.data = data;
      });
    },
  };
}));

export function useUserTmsInfoProvider(): UserTmsInfoState;
export function useUserTmsInfoProvider<T>(selector: (state: UserTmsInfoState) => T, equals?: (a: T, b: T) => boolean): T;
export function useUserTmsInfoProvider(selector?: any, equals?: any) {
  return useStore(useUserTmsInfoStore, selector, equals);
}