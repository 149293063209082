export enum RouteType {
    ROUTE_ERROR = 'RouteError',
    PAGE_NO_HEADER = 'NodePageNoHeader',
    PAGE = 'NodePage',
    PAGE_2COLS = 'NodePage2cols',
    QR_PAGE = 'NodeQrPage',
    HOTEL_SECTION = 'NodeHotelSection',
    DESTINATION_DETAIL = 'NodeDestinationDetail',
    EXPERIENCE_DETAIL = 'NodeExperienceDetail',
    RESIDENT_DETAIL = 'NodeResidentDetail',
    CHECKOUT_STEP_CUSTOMER_INFO = 'CheckoutStepCustomerInfo',
    BOOKING_PAYMENT_IFRAME = 'BookingPaymentIframe',
    BOOKING_PAYMENT_ERROR = 'BookingPaymentError',
    BOOKING_CONFIRMATION = 'BookingConfirmation',
    BOOKING_SEARCH_SET_AND_REDIRECT = 'BookingSearchSetAndRedirect',
    USER_REGISTER = 'UserRegister',
    USER_LOST_PASSWORD = 'UserLostPassword',
    BASIC_PAGE = 'NodeBasicPage',
    BLOG_DETAIL = 'NodeBlogDetail',
    BLOG_CATEGORY = 'TaxonomyTermBlogCategoryDetail',
    JOB_OFFER_DETAIL = 'NodeJobOfferDetail',
    OFFER_GROUP_DETAIL = 'OfferGroupDetail',
    OFFER_DETAIL = 'OfferDetail',
    SITEMAP = 'Sitemap',
    ELBA_MEDIA = 'ElbaMedia',
    ELBA_MEDIA_GOLF = 'ElbaMediaGolf',
}

export interface Route<T = any> {
    isHomePath: boolean
    type: RouteType
    path: string
    url: string
    params: T
    queryParams: NodeJS.Dict<string | string[]>
}
