import Cookies from "js-cookie";
import BrowserUtils from "@/src/ui/utils/browser";
import {T_Currency} from "@/src/core/app/domain/models/currency/T_Currency";

export const CURRENCY_COOKIE_NAME = 'he_currency';
export const CURRENCY_COOKIE_DURATION = 365;

const CurrencyUtils = {
  isValid: (code: string, currencies: Record<string, T_Currency>) => {
    return Object.keys(currencies).indexOf(code) !== -1;
  },

  getCurrencyCodeFromCookie: () : string | undefined => {
    if (!BrowserUtils.isBrowser()) {
      return undefined;
    }
    return Cookies.get(CURRENCY_COOKIE_NAME);
  },

  setCurrencyCodeCookie: (code: string) => {
    Cookies.set(CURRENCY_COOKIE_NAME, code, { expires: CURRENCY_COOKIE_DURATION });
  },
};

export default CurrencyUtils;
