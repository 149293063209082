import {useRouteData} from "@/src/ui/hooks/useRouteData";
import {BookingConfirmationRouteProps} from "@/src/core/app/domain/route/@types/BookingConfirmationRouteProps";
import {useCurrencyConfig} from "@/src/ui/hooks/useCurrencyConfig";
import CheckoutClientFormSummaryPrice from "@/src/ui/components/desktop/CheckoutClientFormSummaryPrice";
import UiCurrencyUtils from "@/src/ui/utils/currency";
import {useTranslation} from "next-i18next";

const BookingConfirmationSummaryPrices = (): JSX.Element => {
  const {t} = useTranslation();
  const routeData = useRouteData<BookingConfirmationRouteProps>();
  const currencyConfig = useCurrencyConfig();
  const reservation = routeData.data.reservation;
  return (
    <article className="p-5 bg-gray-20 text-sm text-brand-2">
      <div className="flex space-y-7.5 sm:space-y-0 flex-col sm:flex-row">
        <CheckoutClientFormSummaryPrice
          title={t('BookingConfirmationSummaryPrices._Index.hotel_payment_label')}
          price={UiCurrencyUtils.format(reservation.total - reservation.total_pay_now, currencyConfig)}
        />
        <CheckoutClientFormSummaryPrice
          title={t('BookingConfirmationSummaryPrices._Index.now_payment_label')}
          price={UiCurrencyUtils.format(reservation.total_pay_now, currencyConfig)}
        />
      </div>
    </article>
  );
}

export default BookingConfirmationSummaryPrices;
