import {CMS_Language} from "@/src/core/app/domain/cms/@types/language/CMS_Language";
import {FormInputSelectOption} from "@/src/ui/@types/form/FormInputSelectOption";

export enum LanguageDropdownMode {
  DEFAULT,
  SHORT,
}

type RenderLabenFn = (item: CMS_Language) => string;

const _renderLabelDefault : RenderLabenFn = (item) => {
  return `${item.label} (${item.code.toUpperCase()})`;
}
const _renderLabelShort  : RenderLabenFn = (item) => {
  return item.code.toUpperCase();
}

const LanguageUtils = {
  isValidLangcode: (languages: CMS_Language[], langcode: string) => {
    return languages.find(item => item.code === langcode) !== undefined;
  },

  getLanguage: (languages: CMS_Language[], langcode: string) : CMS_Language => {
    return languages.find(item => item.code === langcode) as CMS_Language;
  },
  getDropdownOptions: (languages: CMS_Language[], mode = LanguageDropdownMode.DEFAULT) => {
    const fns : Record<LanguageDropdownMode, RenderLabenFn> = {
      [LanguageDropdownMode.DEFAULT] : _renderLabelDefault,
      [LanguageDropdownMode.SHORT] : _renderLabelShort,
    };
    return Object.values(languages).sort((a, b) => {
      if (a.code === 'es') {
        return -1;
      }
      if (b.code === 'es') {
        return 1;
      }
      return 0;
    }).map(item => ({
      label: fns[mode](item),
      value: item.code,
    }));
  },
  getSelectOptions: (languages: CMS_Language[]) : FormInputSelectOption[] => {
    return languages.map(language => ({
      value: language.code,
      label: language.label,
    }));
  },
};

export default LanguageUtils;
