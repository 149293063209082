import {NetworkResponse, NetworkResponseError} from "@/src/common/network/NetworkResponse";
import {RouteErrorException} from "@/src/core/app/domain/route/exception/RouteErrorException";
import SentryUtils from "@/src/common/utils/sentry";
import {ScopeContext} from "@sentry/types/types/scope";
import {RouteProps} from "@/src/ui/@types/RouteProps";
import {DeviceType} from "@/src/ui/@types/Device";
import {Route, RouteType} from "../domain/@types/Route";
import {LayoutType} from "@/src/ui/@types/layout/Layout";
import {T_RouteError} from "@/src/core/app/domain/route/@types/T_RouteError";
import {NotFoundException} from "@/src/core/app/domain/route/exception/NotFoundException";
import {AppInfo} from "@/src/core/app/domain/@types/AppInfo";
import {i18n} from "next-i18next";
import {GetServerSidePropsContext} from "next/types";
import ServerSideUtils from "@/src/core/app/utils/server-side";
import {T_RouteErrorType} from "@/src/core/app/domain/route/@types/T_RouteErrorType";

type ErrorAnalyzerFn = (responseError: NetworkResponseError) => T_RouteError;


const RouteUtils = {
  buildRoute: (
    routeType: RouteType,
    context: GetServerSidePropsContext,
    isHomePath: boolean,
  ) : Route => {
    const queryParams = context.query;
    delete queryParams['path'];

    const path = ServerSideUtils.getPathFromContextWithLangPrefix(context);
    const url = ServerSideUtils.getRelativeUrl(context);

    return {
      isHomePath: isHomePath,
      type: routeType,
      path,
      url,
      params: null,
      queryParams,
    }
  },

  getRoutePathWithoutLangcode: (route: Route) : string => {
    const parts = route.path.split('/');
    return '/' + parts.splice(2).join('/');
  },
  isRoute: (route: Route, path: string) : boolean => {
    return route.path === path;
  },
  defaultErrorAnalyzerFn: (responseError: NetworkResponseError) => ({
    type: T_RouteErrorType.MESSAGE,
    data: {
      message: 'route_utils.generic_notification.error_message',
    }
  }),
  getRouteError: (
    appInfo: AppInfo,
    e: RouteErrorException,
    device: DeviceType,
    route: Route,
  ) : RouteProps => {
    return {
      appInfo,
      device,
      route: {
        isHomePath: false,
        type: RouteType.ROUTE_ERROR,
        path: route.path,
        url: route.url,
        params: route.params,
        queryParams: {},
      },
      routeData: {
        meta: {
          title: i18n?.t('global.generic_notification.error_title', {lng: appInfo.langcode}) || 'Error',
          description: '',
          hreflang: {},
        },
        data: e.data,
      },
      layout: {
        type: LayoutType.ERROR,
      },
      layoutData: null,
    };
  },
  checkResponse: (response: NetworkResponse, message: string, captureContext?: Partial<ScopeContext>, errorAnalyzerFn?: ErrorAnalyzerFn) => {
    if (response.isOk) {
      return;
    }

    const extra = captureContext?.extra || {};
    extra['error_type'] = response.error?.type;
    extra['error_data'] = response.error?.data;

    SentryUtils.captureMessage(message, {
      ...captureContext,
      extra
    });

    const fn = errorAnalyzerFn ?? RouteUtils.defaultErrorAnalyzerFn;
    const routeError = fn(response.error as NetworkResponseError);
    throw new RouteErrorException(routeError);
  },

  throwNotFound: (message: string, captureContext?: Partial<ScopeContext>) => {
    SentryUtils.captureMessage(message, captureContext);
    throw new NotFoundException(message);
  },

  getQueryParamString: (route: Route, key: string, defaultValue? : string) : string | undefined => {
    if (key in route.queryParams) {
      return route.queryParams[key] as string;
    }
    return defaultValue;
  },

  isRouteCacheable: (route: Route) : boolean => {
    const cacheableRoutes : RouteType[]  = [
      RouteType.PAGE_NO_HEADER,
      RouteType.PAGE,
      RouteType.PAGE_2COLS,
      RouteType.QR_PAGE,
      RouteType.HOTEL_SECTION,
      RouteType.DESTINATION_DETAIL,
      RouteType.EXPERIENCE_DETAIL,
      RouteType.RESIDENT_DETAIL,
      RouteType.USER_REGISTER,
      RouteType.USER_LOST_PASSWORD,
      RouteType.BASIC_PAGE,
      RouteType.BLOG_DETAIL,
      RouteType.BLOG_CATEGORY,
      RouteType.JOB_OFFER_DETAIL,
      RouteType.OFFER_GROUP_DETAIL,
      RouteType.OFFER_DETAIL,
      RouteType.SITEMAP,
      RouteType.ELBA_MEDIA,
      RouteType.ELBA_MEDIA_GOLF,
    ];
    return cacheableRoutes.indexOf(route.type) !== -1;
  }
}

export default RouteUtils;
